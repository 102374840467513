/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */

.layout {
    min-height: 100vh;
    
  }

  .logo {
    margin: 16px 24px 16px 0;
    float: left;
  }

  nz-header {

    width: 100%;
  
  }

  [nz-menu] {
    line-height: 64px;
  }

  nz-content {
    padding: 0 50px;
    margin-top: 64px;
  }

  nz-breadcrumb {
    margin: 16px 0;
  }

  .inner-content {
    background: #fff;
    padding: 24px;
    min-height: 380px;
  }

  nz-footer {
    text-align: center;
  }

